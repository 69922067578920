import { useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { Modal, ThemeContext } from '@forma/forma-ui-kit';
import ClientWrap from '@/components/ClientWrap';
import { analytics } from '@/helpers/analytics';
import { useRequestCall } from '../RequestCall';
import RequestDemoModal2Form from './RequestDemoModal2Form';

import styles from './request-demo-modal-2.module.css';
import { ReactSVG } from 'react-svg';

const items = [
  'Интерактивная онлайн-сессия, адаптированная исключительно под вашу бизнес-задачу',
  'Выявим проблемные зоны, препятствующие эффективному развитию бизнеса',
  'Детально ответим на все вопросы',
  'Никаких обязательств по покупке',
  'Перенесем до 5-ти документов бесплатно',
  '14 дней пробного периода бесплатно'
];

const RequestDemoModal2 = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { lang, viewport } = useContext(ThemeContext);
  const isPhone = viewport === 'phone';

  const { onSubmit, isSuccess, isError, reset } = useRequestCall({ lang });

  const handleSubmit = (values) => {
    onSubmit(values).then(success => {
      if (success) {
        analytics.sendRequestForm();
      }
    });
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  //demo

  const title = 'Пробуйте бесплатно 14 дней';

  return (
    <ClientWrap>
      <Modal
        open={open}
        onClose={closeModal}
        width="100%"
        maxWidth="1092px"
        title={isPhone && title}
        viewStyle="no-padding"
        containerClassName={styles.container}
        contentClassName={styles.content}
      >
        <div className={styles.left}>
          <input type="hidden" autoFocus />
          {!isPhone && (
            <div className={styles.title}>{title}</div>
          )}
          {!(isError || isSuccess) && (
            <div className={styles.subtitle}>
              Заполните поля и вы сразу получите бесплатный доступ.
              {' '}Также эксперт сервиса презентует возможности платформы для вашего бизнеса и перенесет до 5-ти документов бесплатно.
            </div>
          )}
          <RequestDemoModal2Form
            onSubmit={handleSubmit}
            isSendSuccess={isSuccess}
            isSendError={isError}
            buttonText={t('activate_demo_access')}
            noticeText={
              <Trans
                i18nKey="user_agreement_form_notice"
                components={{
                  privacy: <Link href="/privacy" />,
                  license: <Link href="/license" />,
                  button: t('activate_demo_access'),
                }}
              />
            }
          />
        </div>
        {!isPhone && (
          <div className={styles.right}>
            <div className={styles.ceo}>
              <img className={styles.ceoImage} src="/images/team/nikita_k.webp" width={76} height={76} alt="Колосов Никита" />
              <div className={styles.ceoInfo}>
                <div className={styles.ceoName}>Колосов Никита</div>
                <div className={styles.ceoPosition}>Сооснователь сервиса</div>
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.infoTitle}>Что ожидать от демонстрации:</div>
              <ul className={styles.infoList}>
                {items.map(item => (
                  <li className={styles.infoItem} key={item}>
                    <ReactSVG src="/icons/checkmark-circle.svg" className={styles.infoIcon} />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </Modal>
    </ClientWrap>
  );
};

export default RequestDemoModal2;

import { useContext } from 'react';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import PageBlock from '@/components/PageBlock';
import { analytics } from '@/helpers/analytics';
import RequestCallForm from './RequestCallForm';
import { useRequestCall } from './useRequestCall';

import styles from './request-call.module.css';

const RequestCall = ({ title, subtitle, style }) => {
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);

  const { onSubmit, isSuccess, isError } = useRequestCall({ lang });

  const handleSubmit = (values) => {
    onSubmit(values).then(success => {
      if (success) {
        analytics.sendRequestForm();
      }
    });
  };

  return (
    <PageBlock id="request_call" className={styles.root} title={title} style={style}>
      {subtitle && (
        <p className={styles.subtitle}>
          {subtitle}
        </p>
      )}
      <RequestCallForm
        onSubmit={handleSubmit}
        isSendSuccess={isSuccess}
        isSendError={isError}
        buttonText={t('call_me')}
        noticeText={
          <Trans
            i18nKey="user_agreement_form_notice"
            components={{
              privacy: <Link href="/privacy" />,
              license: <Link href="/license" />,
              button: t('call_me'),
            }}
          />
        }
      />
    </PageBlock>
  );
};

export default RequestCall;

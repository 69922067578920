import classNames from 'classnames';

import styles from './marquee.module.css';

const Marquee = ({ items, className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.list}>
        {items.map(({ id, name, icon }) => (
          <img src={icon} className={styles.icon} alt={name} key={id} />
        ))}
      </div>
      <div className={styles.list} aria-hidden="true">
        {items.map(({ id, name, icon }) => (
          <img src={icon} className={styles.icon} alt={name} key={id} />
        ))}
      </div>
    </div>
  );
};

export default Marquee;

import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import PageBlock from '@/components/PageBlock';

import styles from './advantages-banner-3.module.css';

// const AdvantagesBlockButton = ({ name, image, link }) => {
//   return (
//     <div className={classNames(styles.block, styles.blockButton)} style={{ backgroundImage: `url(${image})` }}>
//       <div className={styles.blockButtons}>
//         <Button viewStyle="primary" as={Link} href={link}>{name}</Button>
//       </div>
//     </div>
//   );
// };

const lkUrl = process.env.LK_URL;

const AdvantagesBanner3 = ({ items, variant }) => {
  const { t } = useTranslation();
  const { lang, viewport } = useContext(ThemeContext);
  const isPhone = viewport === 'phone';

  return (
    <PageBlock variant={variant} className={styles.root}>
      {items.map(({ name, list, steps, content, image }, index) => (
        <div className={styles.block} key={name}>
          <div className={styles.blockContent}>
            <div className={styles.blockTitle} dangerouslySetInnerHTML={{ __html: name }}></div>
            <div className={styles.blockDescr}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              {list && (
                <ul className={styles.blockList}>
                  {list.map((item, index) =>
                    <li className={styles.blockListItem} key={`list_item_${index}`}>
                      <ReactSVG src="/icons/checkmark-circle.svg" className={styles.blockListItemIcon} />
                      <span dangerouslySetInnerHTML={{ __html: item }} />
                    </li>
                  )}
                </ul>
              )}
            </div>
            {!isPhone && (
              <div className={styles.blockButtons}>
                <Button
                  as={Link}
                  viewStyle="primary"
                  className={styles.blockButton}
                  href={`${lkUrl}/register?lng=${lang}`}
                >
                  {t('start_free')}
                </Button>
              </div>
            )}
          </div>
          <img src={image} className={styles.blockImage} alt="" />
          {isPhone && index === items.length - 1 && (
            <Button
              as={Link}
              viewStyle="primary"
              className={styles.blockButton}
              href={`${lkUrl}/register?lng=${lang}`}
            >
              {t('start_free')}
            </Button>
          )}
        </div>
      ))}
    </PageBlock>
  );
};

export default AdvantagesBanner3;

import { useState } from 'react';
import { requests } from '@/helpers/api';

const useRequestCall = ({ lang }) => {
  const [formStatus, setFormStatus] = useState(null);

  const onSubmit = async (values) => {
    if (!values.clientPhone) {
      setFormStatus('error');
      return false;
    }

    const result = await requests.sendContactsFrom(values, lang);
    if (result) {
      setFormStatus('success');
      return true;
    }

    setFormStatus('error');
    return false;
  };

  const reset = () => {
    setFormStatus(null);
  };

  return { onSubmit, isSuccess: formStatus === 'success', isError: formStatus === 'error', reset };
};

export { useRequestCall };

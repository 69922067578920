import { useState } from 'react';
import Image from 'next/image';
import PageBlock from '@/components/PageBlock';
import RequestRegisterModal from '@/components/blocks/RequestRegisterModal';

import styles from './cards.module.css';

const Cards = ({ title, items }) => {
  const [isRegOpen, setRegOpen] = useState(false);

  return (
    <PageBlock variant="green" className={styles.root} title={title}>
      <div className={styles.items}>
        {items.map(({ title, description, icon }) => (
          <div className={styles.item} onClick={() => setRegOpen(true)} key={title}>
            <Image src={icon} className={styles.icon} width={40} height={40} alt="" />
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        ))}
      </div>
      <RequestRegisterModal
        open={isRegOpen}
        onClose={setRegOpen}
      />
    </PageBlock>
  );
};

export default Cards;

import React from 'react';
import classNames from 'classnames';
import { nanoid } from '@forma/forma-ui-kit';

import styles from './radio-buttons.module.css';

export interface RadioButtonsProps {
  id?: string,
  name: string,
  label?: React.ReactNode,
  placeholder?: string,
  className?: string,
  containerClass?: string,
  labelClass?: string,
  value?: string,
  hidden?: boolean,
  fullWidth?: boolean,
  error?: boolean,
  errorMessage?: React.ReactNode,
  success?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  notice?: React.ReactNode,
  iconClass?: string,
  readOnly?: boolean,
  options: {
    label: string,
    value: string
  }[]
}

const RadioButtons = ({
  id = nanoid(8), name, onChange, onFocus, onBlur, hidden, fullWidth, label, options,
  className, containerClass, labelClass, iconClass, error, errorMessage, success, notice, placeholder, value, ...props
}: RadioButtonsProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) onFocus(e);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);
  };

  return (
    <div className={classNames(styles.container, fullWidth && styles.fullWidth, containerClass, hidden && styles.hidden)}>
      {label && <div className={classNames(styles.label, labelClass)}>{label}:</div>}
      {options.map(option => (
        <label className={styles.option} key={option.value}>
          <input
            {...props}
            type="radio"
            name={name}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={classNames(
              styles.input,
              className,
              error && 'error',
              success && 'success'
            )}
            value={option.value}
            checked={value === option.value}
          />
          <span className={classNames(styles.icon, iconClass, value === option.value && styles.checked)} />
          <span className={styles.optionLabel}>{option.label}</span>
        </label>
      ))}
      {(error && errorMessage) &&
        <span className={styles.errorText}>{errorMessage}</span>
      }
      {notice &&
        <span className={styles.notice}>{notice}</span>
      }
    </div>
  );
};

export default RadioButtons;

import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  phonemasks,
  PhoneInput,
  Input,
} from '@forma/forma-ui-kit';
import classNames from 'classnames';
import ControlField from '@/components/fields/ControlField';
import RadioButtons from '@/components/fields/RadioButtons';

import styles from './request-demo-modal-2.module.css';

const companySizes = [
  '1-10 сотрудников',
  '10-50 сотрудников',
  '50-100 сотрудников',
  '100-500 сотрудников',
];

const RequestDemoModal2Form = ({
  onSubmit,
  isSendSuccess,
  isSendError,
  className,
  buttonText,
  noticeText
}) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [phoneValidation, setPhoneValidation] = useState('');

  useEffect(() => {
    if (i18n && phonemasks) {
      const code = i18n.language === 'en' ? 'US' : i18n.language.toUpperCase();
      const mask = phonemasks.find(({ iso }) => iso === code);
      if (mask) setPhoneValidation(mask.code + mask.mask);
    }
  }, [i18n]);

  const handleChangeMask = ({ mask, code }) => {
    setPhoneValidation(code + mask);
  };

  if (isSendSuccess) return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.result}>
        <div className={styles.resultSubtitle}>
          Вы записались на демо Формы, в ближайшее время мы свяжемся с Вами,
          чтобы выбрать удобную дату и время для онлайн-встречи.
        </div>
      </div>
    </div>
  );

  if (isSendError) return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.result}>
        <div className={styles.resultSubtitle}>{t('contacts_form_send_error')}</div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            viewStyle="primary"
            size="medium"
            onClick={() => window.location.reload()}
          >
            {t('try_again')}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <form className={classNames(styles.root, className)} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        <ControlField
          id="clientFullName"
          component={Input}
          viewStyle="secondary"
          placeholder={t('name') + '*'}
          control={control}
          {...register('clientFullName', {
            required: t('please_fill_the_field'),
            minLength: { value: 3, message: t('errors.min_lenght_3') },
            pattern: { value: /^[^0-9]+$/, message: t('errors.name_has_numbers') }
          })}
          meta={{
            error: errors.name,
            // errorMessage: errors.name?.message,
            containerClass: styles.inputContainer
          }}
          className={styles.input}
        />
        <ControlField
          id="clientPhone"
          component={PhoneInput}
          viewStyle="secondary"
          masks={phonemasks}
          defaultMaskCode={
            i18n.language === 'en' ? 'US' : i18n.language.toUpperCase()
          }
          onChangeMask={handleChangeMask}
          // name="clientPhone"
          placeholder={t('phone_number')}
          control={control}
          error={errors.clientPhone}
          // errorMessage={errors.phone?.message}
          {...register('clientPhone', {
            required: t('please_fill_the_field'),
            minLength: {
              value: phoneValidation.length,
              message: t('errors.min_lenght_phone'),
            },
          })}
          autoComplete="off"
          containerClass={styles.inputContainer}
          className={styles.input}
        />
        <ControlField
          id="clientEmail"
          component={Input}
          viewStyle="secondary"
          placeholder={t('email')}
          control={control}
          {...register('clientEmail', {
            // required: t('please_fill_the_field'),
            minLength: { value: 3, message: t('errors.min_lenght_3') },
            pattern: { value: /\S+@\S+\.\S+/, message: t('errors.email_not_valid') }
          })}
          meta={{
            error: errors.clientEmail,
            // errorMessage: errors.name?.message,
            containerClass: styles.inputContainer
          }}
          className={styles.input}
        />
        <ControlField
          id="companyName"
          component={Input}
          viewStyle="secondary"
          placeholder={t('yours_company_name')}
          control={control}
          {...register('companyName', {
            // required: t('please_fill_the_field'),
            minLength: { value: 3, message: t('errors.min_lenght_3') }
          })}
          meta={{
            error: errors.companyName,
            // errorMessage: errors.name?.message,
            containerClass: styles.inputContainer
          }}
          className={styles.input}
        />
        <ControlField
          id="companySize"
          component={RadioButtons}
          label={t('employees_count')}
          control={control}
          options={companySizes.map(size => ({ label: size, value: size }))}
          {...register('companySize', {
            required: t('please_fill_the_field'),
            // minLength: { value: 3, message: t('errors.min_lenght_3') }
          })}
          error={errors.companySize}
          // errorMessage: errors.name?.message,
          containerClass={styles.inputContainer}
          className={styles.input}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          type="submit"
          className={styles.button}
          viewStyle="primary"
          size="medium"
          disabled={!isValid}
          fullWidth
        >
          {buttonText}
        </Button>
      </div>
      <div className={styles.notice}>
        {noticeText}
      </div>
    </form>
  );
};

export default RequestDemoModal2Form;

import PageBlock from '@/components/PageBlock';

import styles from './counts-2.module.css';

const Counts2 = ({ items }) => {
  if (!items) return null;

  return (
    <PageBlock variant="white" className={styles.root}>
      <div className={styles.content}>
        {items.map(({ title, description }, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.description}>{description}</div>
          </div>
        ))}
      </div>
    </PageBlock>
  );
};

export default Counts2;

import { useContext, useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import PageBlock from '@/components/PageBlock';
import RequestDemoModal2 from '@/components/blocks/RequestDemoModal2';

import styles from './request-demo-banner.module.css';

const RequestDemoBanner = ({ title, subtitle, variant }) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const isPhone = ['phone', 'tabletS'].includes(viewport);
  const [isRequestOpen, setRequestOpen] = useState(false);

  return (
    <PageBlock variant={variant} className={styles.root}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h3 className={styles.title}>
            {title}
          </h3>
          <div className={styles.description}>
            {subtitle}
          </div>
          {!isPhone && (
            <div className={styles.buttons}>
              <Button
                className={styles.button}
                onClick={() => setRequestOpen(true)}
              >
                {t('request_demo')}
              </Button>
            </div>
          )}
        </div>
        <Image src="/images/in-meeting.webp" className={styles.image} width={505} height={291} alt="" />
        {isPhone && (
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              onClick={() => setRequestOpen(true)}
              fullWidth
            >
              {t('request_demo')}
            </Button>
          </div>
        )}
      </div>
      <RequestDemoModal2
        open={isRequestOpen}
        onClose={setRequestOpen}
      />
    </PageBlock>
  );
};

export default RequestDemoBanner;

import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  phonemasks,
  PhoneInput,
} from '@forma/forma-ui-kit';
import classNames from 'classnames';
import ControlField from '@/components/fields/ControlField';

import styles from './request-call.module.css';

const RequestCallForm = ({
  onSubmit,
  isSendSuccess,
  isSendError,
  className,
  buttonText,
  noticeText
}) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [phoneValidation, setPhoneValidation] = useState('');

  useEffect(() => {
    if (i18n && phonemasks) {
      const code = i18n.language === 'en' ? 'US' : i18n.language.toUpperCase();
      const mask = phonemasks.find(({ iso }) => iso === code);
      if (mask) setPhoneValidation(mask.code + mask.mask);
    }
  }, [i18n]);

  const handleChangeMask = ({ mask, code }) => {
    setPhoneValidation(code + mask);
  };

  if (isSendSuccess) return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.result}>
        <div className={styles.resultSubtitle}>
          Вы записались на демо Формы, в ближайшее время мы свяжемся с Вами,
          чтобы выбрать удобную дату и время для онлайн-встречи.
        </div>
      </div>
    </div>
  );

  if (isSendError) return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.result}>
        <div className={styles.resultSubtitle}>{t('contacts_form_send_error')}</div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            viewStyle="primary"
            size="medium"
            onClick={() => window.location.reload()}
          >
            {t('try_again')}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <form className={classNames(styles.root, className)} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        <ControlField
          id="clientPhone"
          component={PhoneInput}
          masks={phonemasks}
          defaultMaskCode={
            i18n.language === 'en' ? 'US' : i18n.language.toUpperCase()
          }
          onChangeMask={handleChangeMask}
          name="clientPhone"
          placeholder={t('phone_number')}
          control={control}
          error={errors.phone}
          // errorMessage={errors.phone?.message}
          {...register('clientPhone', {
            required: t('please_fill_the_field'),
            minLength: {
              value: phoneValidation.length,
              message: t('errors.min_lenght_phone'),
            },
          })}
          autoComplete="off"
          containerClass={styles.inputContainer}
          className={styles.input}
          viewStyle="secondary"
        />

        <Button
          type="submit"
          className={styles.button}
          viewStyle="primary"
          size="medium"
          disabled={!isValid}
        >
          {buttonText}
        </Button>
      </div>

      <div className={styles.notice}>
        {noticeText}
      </div>
    </form>
  );
};

export default RequestCallForm;

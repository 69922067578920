import { useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { Modal, ThemeContext } from '@forma/forma-ui-kit';
import ClientWrap from '@/components/ClientWrap';
import { analytics } from '@/helpers/analytics';
import { useRequestCall } from '../RequestCall';
import RequestRegisterModalForm from './RequestRegisterModalForm';

import styles from './request-register-modal.module.css';

const RequestRegisterModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { lang, viewport } = useContext(ThemeContext);
  const isPhoneOrTablet = ['phone', 'tabletS'].includes(viewport);
  const isPhone = viewport === 'phone';

  const { onSubmit, isSuccess, isError, reset } = useRequestCall({ lang });

  const handleSubmit = (values) => {
    onSubmit(values).then(success => {
      if (success) {
        analytics.sendRequestForm();
      }
    });
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  const title = 'Получите расширенный пробный доступ на 7 дней бесплатно';

  return (
    <ClientWrap>
      <Modal
        open={open}
        onClose={closeModal}
        width="100%"
        maxWidth="1092px"
        title={isPhone && title}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <input type="hidden" autoFocus />
            {!isPhone && (
              <div className={styles.title}>{title}</div>
            )}
            {!(isError || isSuccess) && (
              <div className={styles.subtitle}>
                Заполните поля для получения доступа и переноса до 5-ти ваших документов на сервис бесплатно.
              </div>
            )}
            {isPhoneOrTablet && (
              <Image className={styles.image} src="/images/browser-filling.svg" width={540} height={272} alt="" />
            )}
            <RequestRegisterModalForm
              onSubmit={handleSubmit}
              isSendSuccess={isSuccess}
              isSendError={isError}
              buttonText={t('start_free')}
              noticeText={
                <Trans
                  i18nKey="user_agreement_form_notice"
                  components={{
                    privacy: <Link href="/privacy" />,
                    license: <Link href="/license" />,
                    button: t('start_free'),
                  }}
                />
              }
            />
          </div>
          {!isPhoneOrTablet && (
            <Image className={styles.image} src="/images/browser-filling.svg" width={540} height={272} alt="" />
          )}
        </div>
      </Modal>
    </ClientWrap>
  );
};

export default RequestRegisterModal;

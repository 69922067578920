import classNames from 'classnames';
import Marquee from '@/components/Marquee';
import PageBlock from '@/components/PageBlock';

import styles from './social-media-slider.module.css';

const SocialMediaSlider = ({ title, items, className, marqueeClassName }) => {
  return (
    <PageBlock className={classNames(styles.root, className)} title={title}>
      <Marquee items={items} className={classNames(styles.marquee, marqueeClassName)} />
    </PageBlock>
  );
};

export default SocialMediaSlider;
